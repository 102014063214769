import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { Box, Button, Link, Typography } from '../..';
import heroImg from '../../../assets/unsplash/efe-kurnaz-RnCPiXixooY-unsplash.jpg';
import websiteCopy from '../../../copy';

const StyledHero = styled(Box)(
  css({
    paddingTop: ['146px', '146px', '288px'],
    paddingBottom: ['246px', '246px', '256px'],
    paddingLeft: [5, 5, 200, 165, 370],
    paddingRight: [5, 5, 6, 6],
    background: `no-repeat url(${heroImg}) center center`,
    backgroundSize: 'cover'
  })
);

export const Hero = () => {
  return (
    <StyledHero>
      <Box width={['100%', '100%', '540px', '700px']}>
        <Typography
          variant="display"
          color="light"
          fontSize={['48px', '48px', '72px']}
        >
          { websiteCopy('PRODUCT_PAGE_HEADER_TITLE') }
        </Typography>
        <Typography color="light" mt={['6']}>
          { websiteCopy('PRODUCT_PAGE_HEADER_DESC') }
        </Typography>
      </Box>
      <Box mt={['48px']} width={['auto', 'auto', '324px']}>
        <Button fluid as={Link} to="/#signup-form">
        { websiteCopy('SIGNUP_BUTTON_TEXT') }
        </Button>
      </Box>
    </StyledHero>
  );
};
