import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Slider from 'react-slick';
import { Box, Image, Typography } from '../..';
import fcEasiness from '../../../assets/fc-easiness.svg';
import fcFlexible from '../../../assets/fc-flexible.svg';
import fcRespo from '../../../assets/fc-responsive.svg';
import fcSche from '../../../assets/fc-schedule.svg';
import fcSup from '../../../assets/fc-support.svg';
import fcTypo from '../../../assets/fc-typo.svg';
import websiteCopy from '../../../copy';

const BgText = styled(Typography)(
  css({
    position: 'absolute',
    color: 'white',
    opacity: 0.5,
    fontSize: '80px',
    lineHeight: 1,
    zIndex: 0
  })
);

const StyledReasons = styled(Box)(
  css({
    position: 'relative',
    paddingTop: ['90px', '90px', '210px'],
    paddingBottom: ['90px', '90px', '126px'],
    px: ['5', '5', '80px', '80px', '150px', '370px'],
    background: 'rgb(235, 247, 255)'
  }),
  {
    [`& *:not(${BgText})`]: {
      position: 'relative',
      zIndex: 1
    }
  }
);

const CarouselItem = ({ icon, title, desc }) => (
  <Box
    maxWidth={['250px', '250px', '300px', '380px', '450px', '550px']}
    flexBasis={['100%', '50%']}
    marginTop={[0, 0, '120px']}
    flexShrink={0}
  >
    <Image src={icon} width="90px" height="90px" />
    <Typography variant="header" marginTop={['6']}>
      {title}
    </Typography>
    <Typography marginTop={['3']}>{desc}</Typography>
  </Box>
);

const CarouselWrap = styled(Box)(props =>
  css({
    '& .slick-slide': {
      marginLeft: 0,
      transition: 'all 250ms ease-in-out'
    },
    '& .slick-slide:not(.slick-active)': {
      opacity: 0.3
    },
    '& .slick-slide.slick-current': {
      marginRight: [-80, -120, -200]
    }
  })
);

const carouselOpts = [
  {
    icon: fcEasiness,
    title: websiteCopy('FEATURES_EASE_IN_EDITING_TITLE'),
    desc: websiteCopy('FEATURES_EASE_IN_EDITING_DESC')
  },
  {
    icon: fcSche,
    title: websiteCopy('FEATURES_SCHEDULED_CONTENT_TITLE'),
    desc: websiteCopy('FEATURES_SCHEDULED_CONTENT_DESC')
  },
  {
    icon: fcSup,
    title: websiteCopy('FEATURES_COLLABORATION_TITLE'),
    desc: websiteCopy('FEATURES_COLLABORATION_DESC')
  },
  {
    icon: fcFlexible,
    title: websiteCopy('FEATURES_FLEXIBLE_PROJECT_MANGEMENT_TITLE'),
    desc: websiteCopy('FEATURES_FLEXIBLE_PROJECT_MANGEMENT_DESC')
  },
  {
    icon: fcRespo,
    title: websiteCopy('FEATURES_RESPONSIVE_TITLE'),
    desc: websiteCopy('FEATURES_RESPONSIVE_DESC')
  },
  {
    icon: fcTypo,
    title: websiteCopy('FEATURES_TYPOGRAPHY_TITLE'),
    desc: websiteCopy('FEATURES_TYPOGRAPHY_DESC')
  }
];

export const Reasons = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const items = carouselOpts.map(item => (
    <CarouselItem key={item.icon} {...item} />
  ));
  return (
    <StyledReasons>
      <Box
        display={['block', 'block', 'flex']}
        flexDirection={[null, null, 'column', 'column', 'column', 'row']}
      >
        <Box flexShrink={0} marginRight={[0, 0, '120px']}>
          <Typography variant="display" fontSize={['48px', '72px']}>
            { websiteCopy('CREATIVES_TITLE') }
          </Typography>
        </Box>

        <Box mt={[5, 5, 6, 6, 6, 0]} maxWidth="550px">
          <Typography variant="body" fontSize={['20px']}>
            { websiteCopy('CREATIVES_DESC') }
          </Typography>
        </Box>
      </Box>

      <BgText>
        For <br /> Creatives
      </BgText>
      <Box mx={['-20px', 0]}>
        <CarouselWrap
          display={['block', 'block', 'none']}
          paddingLeft={['5']}
          mt="9"
        >
          <Slider {...settings}>{items}</Slider>
        </CarouselWrap>

        <Box
          display={['none', 'none', 'flex']}
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {items}
        </Box>
      </Box>
    </StyledReasons>
  );
};
