import React from 'react';
import { MainLayout } from '../layouts';
import { SiteMetadata } from '../components/site-metadata';
import { Hero } from '../components/pages/how-it-works/Hero';
import { Concerns } from '../components/pages/how-it-works/Concerns';
import { Reasons } from '../components/pages/how-it-works/Reasons';

// import { Innovative } from '../components/pages/how-it-works/Innovative';

import { SignUp } from '../components/pages/shared/SignUp';

export default () => (
  <MainLayout>
    <SiteMetadata pathname="/how-it-works" title="AirPage™ Product" />
    <Hero />
    <Concerns />
    <Reasons />
    {/* <Innovative /> */}
    <SignUp />
  </MainLayout>
);
