import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { Box, Image, Typography } from '../..';
import websiteCopy from '../../../copy';
import creativeImg from '../../../assets/unsplash/riccardo-annandale-7e2pe9wjL9M-unsplash-fit.jpg';
import concernImg from '../../../assets/unsplash/john-schnobrich-2FPjlAyMQTA-unsplash-fit.jpg';

const StyledConcerns = styled(Box)(
  css({
    paddingTop: [0, 0, '240px'],
    paddingBottom: ['90px', '90px', '126px'],
    px: ['5', '5', '80px', '100px', '200px', '370px'],
    bg: 'white'
  })
);

const StyledConcernItem = styled(Box)(
  css({
    display: ['block', 'block', 'block', 'flex']
  })
);

const StyledOffsetImg = styled(Box)(
  css({
    transform: ['translateY(48px)']
  })
);
export const Concerns = () => {
  return (
    <StyledConcerns>
      <StyledConcernItem flexDirection="row-reverse">
        <Box flexShrink={0} textAlign="center">
          <Image
            src={creativeImg}
            height={['439px', '439px', '628px']}
            marginTop={['-100px', '-200px', '-350px']}
          />
        </Box>

        <Box marginTop={['6']} marginRight={[0, 0, 0, '120px']}>
          <Typography variant="title" fontSize={['24px', '24px', '36px']}>
            { websiteCopy('PRODUCT_PAGE_1_TITLE') }
          </Typography>
          <Typography marginTop={['6']}>
            { websiteCopy('PRODUCT_PAGE_1_DESC') }
          </Typography>
        </Box>
      </StyledConcernItem>

      <StyledConcernItem flexDirection="row" marginTop={['66px', '88px']}>
        <StyledOffsetImg
          flexShrink={0}
          marginRight={[0, 0, 0, '120px']}
          textAlign="center"
          zIndex="1"
        >
          <Image
            src={concernImg}
            height={['439px', '439px', '628px', '391px']}
          />
        </StyledOffsetImg>
        <Box marginTop={['100px']} marginRight={[0, 0, '120px']}>
          <Typography
            variant="title"
            fontSize={['24px', '24px', '36px']}
            marginTop={['6']}
          >
            { websiteCopy('PRODUCT_PAGE_2_TITLE') }
          </Typography>
          <Typography marginTop={['6']}>
            { websiteCopy('PRODUCT_PAGE_2_DESC') }
          </Typography>
        </Box>
      </StyledConcernItem>
    </StyledConcerns>
  );
};
